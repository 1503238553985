<template>
  <div>
    <!-- Mobile Tab -->
    <div v-if="$vuetify.breakpoint.mdAndDown" style="text-align: center">
      <v-btn class="text-center history-tab-mobile" style="margin-right: 5px"
             :color="selectedTab == 'CLAIM' ? 'primary' : ''" depressed @click="onChangeTab('CLAIM')">
        <referral-summary class="mobile-icon"
                          :class="selectedTab == 'CLAIM' ? 'mobile-icon-active' : ''"></referral-summary>
        <span>
          <span>{{ $t(`label.claim`) }}</span>
        </span>
      </v-btn>
      <v-btn class="text-center history-tab-mobile" style="margin-left: 5px"
             :color="selectedTab == 'OVERVIEW' ? 'primary' : ''" depressed @click="onChangeTab('OVERVIEW')">
        <referral-report class="mobile-icon"
                         :class="selectedTab == 'OVERVIEW' ? 'mobile-icon-active' : ''"></referral-report>
        <span>{{ $t(`label.downlineReport`) }}</span>
      </v-btn>
    </div>
    <!-- End Mobile Tab -->

    <v-card class="desktop_card" elevation="0">
      <v-card-title class="justify-space-between" v-if="$vuetify.breakpoint.mdAndUp">
        <label class="--v-primary-base text-left">{{ $t(`label.referral`) }}</label>
      </v-card-title>
      <!-- Desktop Tab -->
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
          <v-btn class="player-history-tab" :color="selectedTab == 'CLAIM' ? 'primary' : ''" depressed
                 @click="onChangeTab('CLAIM')">
            {{ $t(`label.claim`) }}
          </v-btn>
          <v-btn class="player-history-tab" :color="selectedTab == 'OVERVIEW' ? 'primary' : ''" depressed
                 @click="onChangeTab('OVERVIEW')">
            {{ $t(`label.downlineReport`) }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- End Desktop Tab -->

      <v-card class="history-card" elevation="0">
        <!-- Desktop -->
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
          <!-- Start Claim Bonus UI-->
          <v-col cols="12" md="10" sm="10" class="mx-auto mt-12" v-if="selectedTab == 'CLAIM'">
            <v-card elevation="0" color="commission-content-holder base pa-2 mb-3">
              <v-card-text style="display: inline-flex" class="justify-center">
                <div>
                  <app-form-field style="width: 500px" :enabledLabel="true" :label="$t(`label.referral`)"
                                  :class="'mt-2'" :readonly="true"
                                  v-model.trim="referralOverview.my_referral_code"></app-form-field>
                </div>
                <div>
                  <v-btn class="font-weight-bold pa-2 ml-8 mt-10" style="width: auto; margin: auto"
                         :class="'buttonPrimary theme-button theme--light subtitle-1'" @click="share()">
                    <v-icon class="mr-2">share</v-icon>
                    {{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copyRef`) }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
            <div style="display: flex">
              <v-card elevation="0" color="commission-content-holder primary pa-2 mb-3 col-5">
                <v-card-text class="ma-3">
                  <v-row>
                    <v-col cols="12" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text">{{ $t(`label.freeBonus`) }} </label>
                      <v-card class="font-weight-bold"
                              color="border-left-radius title_color2 white--text text-center pa-1 ml-0 mx-auto"
                              style="width: 215px">
                        {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text" style="width: 90px">{{
                          $t(`label.deposit`)
                        }}</label>
                      <v-card elevation="0"
                              color="border-left-radius title_color2 white--text text-center pa-1 mx-auto ml-0"
                              style="width: 215px">
                        {{ decimalTruncate(downlineDetail.current_deposit, 2) }} /
                        {{ decimalTruncate(downlineDetail.required_deposit, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text" style="width: 90px">{{
                          $t(`label.turnover`)
                        }}</label>
                      <v-card elevation="0"
                              color="border-left-radius title_color2 white--text text-center pa-1 mx-auto ml-0"
                              style="width: 215px">
                        {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }} /
                        {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <!--<app-button class="dialog-button mt-6" style="width: 315px" :disabled="downlineDetail.is_claimed != 1" :action="this.claimBonusDialog" :title="$t(`button.claimBonus`)"></app-button>-->
                </v-card-text>
              </v-card>
              <v-card elevation="0" color="commission-content-holder base pa-2 mb-3 col-7">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <label class="--v-primary-base" style="font-size: 16px">{{
                          $t(`label.youReferralStatus`)
                        }}</label>
                      <div style="display: flex" class="mt-10 justify-center">
                        <div style="width: fit-content; border-right: 2px dotted rgba(8,8,8);" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                          </div>
                          <div>
                            <label>Friend's Accepted</label>
                          </div>
                        </div>
                        <div style="width: fit-content" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                          </div>
                          <div>
                            <label>Friend's Complete</label>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- End Claim Bonus UI-->

          <!-- Start Downline List UI-->
          <v-col cols="12" v-if="selectedTab == 'OVERVIEW'">
            <v-data-table light :headers="detailHeaders" :items="downlineList" class="elevation-0"
                          :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start"
                      style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers"
                      :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                  <th class="referral-table-header text-end"
                      style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y
                            transition="scale-transition" max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="dateRangeText"
                            clearable
                            @click:clear="clearFilter()"
                            outlined
                            class="date-search ml-3 elevation-0 text-center"
                            hide-details="true"
                            v-on="on"
                            append-icon="search"
                        ></v-text-field>
                      </template>
                      <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates"
                                     @input="selectedDate()" range></v-date-picker>
                    </v-menu>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.membercode }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.created_at }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ decimalTruncate(item.required_deposit, 2) }} / {{ decimalTruncate(item.current_deposit, 2) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ decimalTruncate(item.required_valid_bet, 2) }} / {{ decimalTruncate(item.current_valid_bet, 2) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ decimalTruncate(item.bonus_amount, 2) }}
                  </td>
                  <td class="text-start text-capitalize"
                      style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    <v-icon color="red" class="mb-1" small v-if="item.process_status == 4">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.process_status == 3">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.process_status == 2">watch_later</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.process_status == 1">watch_later</v-icon>
                    <span v-if="item.process_status == 4">{{ $t(`affiliateBonusStatus.fail`) }}</span>
                    <span v-if="item.process_status == 3">{{ $t(`affiliateBonusStatus.success`) }}</span>
                    <span v-if="item.process_status == 2">{{ $t(`affiliateBonusStatus.pending`) }}</span>
                    <span v-if="item.process_status == 1">{{ $t(`affiliateBonusStatus.process`) }}</span>
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
            <v-row>
              <v-col cols="12" v-if="downlineList.length == 0" class="text-center pt-0 pb-8 mt-4">
                <label class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Downline List UI-->
        </v-row>
        <!-- End Desktop -->

        <!-- Mobile -->
        <v-row no-gutters v-else>
          <!-- Start Claim Bonus Mobile UI-->
          <v-col cols="12" class="mt-3" v-if="this.selectedTab == 'CLAIM'">
            <v-card elevation="0" color="commission-content-holder base pa-2 mb-3">
              <v-card-text style="display: inline-flex">
                <v-row justify="center" no-gutters>
                  <v-col cols="9" class="mb-3 text-center">
                    <label class="black--text">{{ $t(`label.referral`) }}</label>
                  </v-col>
                  <v-col cols="9" class="mb-0">
                    <v-form ref="redeemForm">
                      <app-form-field
                          :customClass="'commission-redeem-field input-field elevation-0 hide-details'"
                          :enabledLabel="false"
                          v-model.trim="referralOverview.my_referral_code"
                          :readonly="true"
                          :rules="validator.requiredRules()"
                      ></app-form-field>
                    </v-form>
                  </v-col>
                  <v-col cols="9">
                    <app-button class="dialog-button" :action="this.share"
                                :title="webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copyRef`)"></app-button>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div>
              <v-card elevation="0" color="commission-content-holder primary pa-2 mb-3 col-12">
                <v-card-text class="pr-0">
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text">{{ $t(`label.freeBonus`) }} </label>
                      <v-card class="font-weight-bold"
                              color="border-left-radius title_color2 white--text text-center pa-1 ml-0 mx-auto"
                              style="width: 160px">
                        {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text" style="width: 90px">{{
                          $t(`label.deposit`)
                        }}</label>
                      <v-card elevation="0"
                              color="border-left-radius title_color2 white--text text-center pa-1 mx-auto ml-0"
                              style="width: 160px">
                        {{ decimalTruncate(downlineDetail.required_deposit, 2) }} /
                        {{ decimalTruncate(downlineDetail.current_deposit, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex">
                      <label class="mx-auto ml-0 mr-6 pa-1 white-text" style="width: 90px">{{
                          $t(`label.turnover`)
                        }}</label>
                      <v-card elevation="0"
                              color="border-left-radius title_color2 white--text text-center pa-1 mx-auto ml-0"
                              style="width: 160px">
                        {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }} /
                        {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }}
                      </v-card>
                    </v-col>
                  </v-row>
                  <!--<app-button class="dialog-button mt-6" style="width: 225px" :disabled="downlineDetail.is_claimed != 1" :action="this.claimBonusDialog" :title="$t(`button.claimBonus`)"></app-button>-->
                </v-card-text>
              </v-card>
              <v-card elevation="0" color="commission-content-holder base pa-2 mb-3 col-12">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <label class="--v-primary-base text-left" style="font-size: 16px">{{
                          $t(`label.youReferralStatus`)
                        }}</label>
                      <div style="display: flex" class="mt-7 justify-center">
                        <div style="width: fit-content; border-right: 2px dotted rgb(8,8,8);" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                          </div>
                          <div>
                            <label>Friend's Accepted</label>
                          </div>
                        </div>
                        <div style="width: fit-content" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                          </div>
                          <div>
                            <label>Friend's Complete</label>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- End Claim Bonus Mobile UI-->

          <v-col cols="12" v-if="this.selectedTab == 'OVERVIEW'">
            <v-col cols="12" class="pt-4 pb-2 px-4">
              <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="dateRangeText"
                      clearable
                      @click:clear="clearFilter()"
                      color="buttonPrimary"
                      outlined
                      class="date-search-mobile mb-3 elevation-0"
                      hide-details="true"
                      v-on="on"
                      append-icon="search"
                  ></v-text-field>
                </template>
                <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates"
                               @input="selectedDate()" range></v-date-picker>
              </v-menu>
            </v-col>
            <v-row v-for="(item, index) in downlineList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in detailHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.membercode }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.created_at }}
                  </v-col>
                  <v-col cols="12">{{ decimalTruncate(item.required_deposit, 2) }} /
                    {{ decimalTruncate(item.current_deposit, 2) }}
                  </v-col>
                  <v-col cols="12">{{ decimalTruncate(item.required_valid_bet, 2) }} /
                    {{ decimalTruncate(item.current_valid_bet, 2) }}
                  </v-col>
                  <v-col cols="12">
                    {{ decimalTruncate(item.bonus_amount, 2) }}
                  </v-col>
                  <v-col cols="12">
                    <v-icon color="red" class="mb-1" small v-if="item.process_status == 4">cancel</v-icon>
                    <v-icon color="green" class="mb-1" small v-if="item.process_status == 3">check_circle</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.process_status == 2">watch_later</v-icon>
                    <v-icon color="yellow" class="mb-1" small v-if="item.process_status == 1">watch_later</v-icon>
                    <span v-if="item.process_status == 4">{{ $t(`affiliateBonusStatus.fail`) }}</span>
                    <span v-if="item.process_status == 3">{{ $t(`affiliateBonusStatus.success`) }}</span>
                    <span v-if="item.process_status == 2">{{ $t(`affiliateBonusStatus.pending`) }}</span>
                    <span v-if="item.process_status == 1">{{ $t(`affiliateBonusStatus.process`) }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="downlineList.length == 0" class="text-center pt-0 pb-8 mt-5">
                <span class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- End Mobile -->
      </v-card>
      <v-row class="mt-1" v-if="downlineListPagination.last_page > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
              class="trxn_history_pagination"
              v-model="downlineListPagination.current_page"
              :length="downlineListPagination.last_page"
              @input="changePage"
              :next-icon="'arrow_forward_ios'"
              :prev-icon="'arrow_back_ios'"
              :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper, locale, errorCodeHelper, formValidator, ddlHandler} from '@/util'
import {SHARED, SESSION} from '@/constants/constants'
import _ from 'lodash'
import stringFormat from 'string-format'
import {
  AFFILIATE_DOWNLINE_LIST,
  AFFILIATE_DOWNLINE_DETAIL,
  AFFILIATE_UPLINE_REFERRAL,
  AFFILIATE_CLAIM_BONUS,
  AFFILIATE_RESET_CLAIM_BONUS
} from '@/store/affiliate.bonus.module'
import {REFERRAL_GET_OVERVIEW} from '@/store/referral.module'
import currencySymbolMapp from 'currency-symbol-map'
import ReferralSummary from '@/assets/svg/referral-summary.svg'
import ReferralReport from '@/assets/svg/referral-report.svg'

export default {
  name: 'affiliateOne-TimeBonus',
  components: {
    ReferralReport,
    ReferralSummary
  },
  data: () => ({
    share_url: '',
    dateMenu: false,
    selectMenu: false,
    dates: ['', ''],
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'CLAIM',
    currencySymbolMapp: currencySymbolMapp,
    stringFormat: stringFormat,
    selectedList: [],
    currentUser: localStorage.getItem(SESSION.USERNAME),
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    selectedPage: 1,
    overview: {},
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    detailHeaders: [
      {
        text: 'friend',
        align: 'start',
        sortable: false
      },
      {
        text: 'linkedTime',
        sortable: true
      },
      {
        text: 'totalDeposit',
        sortable: false
      },
      {
        text: 'turnover',
        sortable: false
      },
      {
        text: 'bonusAmount',
        sortable: true
      },
      {
        text: 'status',
        sortable: true
      }
    ]
  }),
  created() {
    this.getDownlineDetail()
    this.getUplineReferral()
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
    downlineDetail() {
      return this.$store.state.affiliateBonus.affiliateDownlineDetail
    },
    downlineList() {
      return this.$store.state.affiliateBonus.affiliateDownlineList.data
    },
    downlineListPagination() {
      return this.$store.state.affiliateBonus.affiliateDownlineList.pagination
    },
    uplineReferral() {
      return this.$store.state.affiliateBonus.affiliateUplineReferral
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    claimBonus() {
      return this.$store.state.affiliateBonus.affiliateClaimBonus.complete
    },
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview
      if (window.location.host.toLowerCase() === '897909.com') {
        this.share_url = 'https://babu88.co' + '?refer_code=' + this.overview.my_referral_code
      } else {
        // If not, construct the share URL normally
        this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
      }
    },
    downlineDetail() {
      let i = this.$store.state.affiliateBonus.affiliateDownlineDetail
      return i
    },
    claimBonus() {
      let response = this.$store.state.affiliateBonus.affiliateClaimBonus
      if (response.complete) {
        this.claimBonusCompleteDialog(response)
        this.$store.dispatch(`${AFFILIATE_RESET_CLAIM_BONUS}`)
        this.getDownlineDetail()
      }
    }
  },
  methods: {
    // start change tap
    changePage(targetPage) {
      this.selectedPage = targetPage
      if (this.selectedTab == 'OVERVIEW') {
        this.getDownlineList()
      }
    },
    onChangeTab(type, filterDate) {
      this.selectedTab = type
      if (!filterDate) {
        this.dates = ['', '']
      }
      this.selectedPage = 1

      if (this.selectedTab == 'OVERVIEW') {
        this.getDownlineList(type)
      }
    },
    clearFilter() {
      this.dates = []
      this.onChangeTab(this.selectedTab)
    },
    filterListByDate() {
      this.onChangeTab(this.selectedTab, true)
    },
    sortTableList(obj) {
      let headers = []
      if (this.selectedTab == 'OVERVIEW') {
        headers = this.detailHeaders
      }
      let sortBy = _.filter(headers, function (d) {
        return d.sortable
      })
      if (obj) {
        let selected = _.find(sortBy, function (d) {
          if (d.value == obj.value) {
            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
            return true
          }
        })
      }
      this.historyList = _.orderBy(this.historyList, _.map(sortBy, 'value'), _.map(sortBy, 'sortBy'))
    },
    // end change tap

    // start claim bonus dialog
    claimBonusDialog() {
      let self = this
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.oneTimeBonus`)
      dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmOneTimeBonus`), self.$options.filters.currency(this.downlineDetail.bonus_amount, this.currentCurrency)))
      dialog.button.push({
        title: locale.getMessage(`label.confirm`),
        action: () => this.affiliateClaimBonus()
      })
      this.openPageDialog(dialog)
    },
    claimBonusCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.openAlert(true, locale.getMessage(`message.affiliateBonusSuccess`))
      }
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    // end claim bonus dialog

    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        this.$copyText(this.share_url).then(
            function (e) {
              alert("Referral Link Copied!")
            }
        )
      }
    },
    copy() {
      this.$copyText(this.overview.my_referral_code).then(
          function (e) {
            alert(locale.getMessage('label.copied'))
          },
          function (e) {
            alert(locale.getMessage('label.copyFailed'))
          }
      )
    },
    decimalTruncate(num, fixed) {
      if (num == null) {
        num = 0
      }
      let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
      return parseFloat(num.toString().match(re)[0])
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function () {
          self.dateMenu = false
        }, 500)
      }
    },

    // start dispatch API
    getDownlineDetail() {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    },
    getDownlineList() {
      let filter = {
        start_time: this.dates[0],
        end_time: this.dates[1],
        page: this.selectedPage,
        size: 10
      }
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_LIST}`, {filter})
    },
    getUplineReferral() {
      this.$store.dispatch(`${AFFILIATE_UPLINE_REFERRAL}`)
    },
    affiliateClaimBonus() {
      this.$store.dispatch(`${AFFILIATE_CLAIM_BONUS}`)
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    }
    // end dispatch API
  }
}
</script>

<style lang="scss">
.commission-content-holder {
  border-radius: 20px !important;
}

.referral-text {
  font-size: 50px;
  font-weight: bold;
}

.white-text {
  color: #fff !important;
  caret-color: #fff !important;
}

.v-application .primary {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}

.trxn_history_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.mobile-history-table {
  .col-12 {
    min-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
  }
}

.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0px 40px;
  border-radius: 10px !important;

  .date-search {
    width: fit-content;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }

  .referral-table-header {
    color: #acacac;
  }
}

.date-search-mobile {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-input__append-inner {
    margin-top: 8px;
  }
}

.mobile-history-card {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.player-history-tab {
  width: 140px;
  margin-right: 15px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
}

.history-tab-mobile {
  width: fit-content !important;
  min-width: 150px !important;
  padding: 2px;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 14px !important;

  .mobile-icon {
    margin-right: 3px;
    width: 30px;
    height: 30px;
  }

  .mobile-icon-active {
    fill: #3a3a3a !important;

    path {
      fill: #fff !important;
    }
  }
}

.text-start {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.text-center {
  margin: auto;
  white-space: nowrap;
}

.pt-4.pb-2.px-4.mobile-provider-padding.col.col-12 {
  padding-top: 0px !important;
}

.pt-4.pb-2.px-4.date-padding.col.col-12 {
  padding-bottom: 0px !important;
}

.provider-select {
  top: 13px;
}

.mobile-provider-select {
  top: 5px;
}

.mobile-provider-padding .v-input .v-label {
  top: 10px !important;
}

.provider-padding .v-input .v-label {
  top: 15px !important;
}

@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    margin: 0px 25px;
    overflow-x: hidden;
    overflow-y: hidden;

    .referral-table-header {
      color: #acacac;
    }
  }
}
</style>
